import axios from "./axios.js"

const http = {
	//公共接口 选择区域
	getArea: function(body) {
		return axios('get', '/competition_course_region/list?page=1&limit=0', body)
	},
	//获取验证码
	getCode: function(head, body) {
		return axios('post', '/sms/send-captcha' + head, body) //post方法以及传参    qwe是字段   e是参数
	},
	//注册
	register: function(head, body) {
		return axios('post', '/auth/register' + head, body)
	},
	//登录
	login: function(head, body) {
		return axios('post', '/auth/login' + head, body)
	},
	//判断是否注册过手机号
	is_register:function(head){
		return axios('post', '/auth/is-registered' + head)
	},
	//忘记密码
	forget: function(head, body) {
		return axios('post', '/auth/forget-password' + head, body)
	},
	//征集要求
	requirement: function(head, body) {
		return axios('get', '/competition_course_requirement/detail' + head, body)
	},
	//活动通知
	notification: function(head, body) {
		return axios('get', '/competition_course_notification/detail' + head, body)
	},
	//参赛说明
	explain: function(head, body) {
		return axios('get', '/competition_course_explain/detail' + head, body)
	},
	// 学习资料
	study: function(head, body) {
		return axios('get', '/competition_course_study/detail' + head, body)
	},
	//课程竞赛配置详情
	config_detail: function(head, body) {
		return axios('get', '/competition_config/detail' + head, body)
	},
	//获取课程分类
	course_list: function(head, body) {
		return axios('get', '/course_category/list' + head, body)
	},
	// 获取课程主题树形结构
	course_tree: function(head, body) {
		return axios('get', '/course_category/tree' + head, body)
	},
	//行政区域接口
	region: function(head, body) {
		return axios('get', '/region/list' + head, body)
	},
	//获取查看示例的配置
	courseExampleDetail: function(head, body) {
		return axios('get', '/competition_course_example/detail' + head, body)
	},
	//获取课程列表
	courseList: function(head, body) {
		return axios('get', '/staff/competition_course/list' + head, body)
	},
	//删除
	deleteList: function(head, body) {
		return axios('post', '/staff/competition_course/delete' + head, body)
	},
	//添加
	courseStore: function(head, body) {
		return axios('post', '/staff/competition_course/store' + head, body)
	},
	//获取详情
	getDetail: function(head, body) {
		return axios('get', '/staff/competition_course/detail' + head, body)
	},
	//编辑
	update: function(head, body) {
		return axios('post', '/staff/competition_course/update' + head, body)
	},
	//撤回
	courseRevoke: function(head) {
		return axios('post', '/staff/competition_course/revoke' + head)
	},
	//获取课程竞赛自荐列表
	getRecommendationList: function(body) {
		return axios('get', '/staff/competition_course_author_recommendation_signature/list', body)
	},
	//获取学校列表
	getSchoolList: function(head, body) {
		return axios('get', '/resident_school/list' + head, body)
	},
	// 获取显不显示教育局
	getCourseRegionDetail: function(head) {
		return axios('get', '/competition_course_region/detail' + head)
	},
	// 获取添加课程时课程主题
	getCourseCategoryList: function(head, body) {
		return axios('get', '/course_category/list' + head, body)
	},
	// 获取超管配置的添加课程时 单位类型
	getAffiliationList: function(head) {
		return axios('get', '/competition_course_affiliation_type_for_region/list' + head)
	},
	// 获取超管配置的添加课程时key（课程或案例）
	getCourseFrontKey: function(head) {
		return axios('get', '/competition_course_front_key_for_region/list' + head)
	},
	/*
		专家接口
	*/
	// 列表
	getExpertList: function(head, body) {
		return axios('get', '/competition_course_expert/competition_course/list' + head, body)
	},
	//获取列表详情
	getExpertDetail: function(head) {
		return axios('get', '/competition_course_expert/competition_course/detail' + head)
	},
	//获取评价标签列表
	getCommentList: function(body) {
		return axios('get', '/competition_course_expert/competition_course_comment_signature/list', body)
	},
	//获取评论详情
	getCommentDetail: function(head) {
		return axios('get', '/competition_course_expert/competition_course_comment/detail' + head)
	},
	//编辑评论
	commentUpdate: function(head, body) {
		return axios('post', '/competition_course_expert/competition_course_comment/update' + head, body)
	},
	//提交评价
	commentSure: function(head, body) {
		return axios('post', '/competition_course_expert/competition_course_comment/store' + head, body)
	},
	
	/* 教育主管部门 */
	getGovernmentList: function(head, body) {
		return axios('get', '/government/competition_course/list' + head, body)
	},
	courseConfirm: function(head, body) {
		return axios('post', '/government/competition_course/confirm' + head, body)
	},
	courseReject: function(head, body) {
		return axios('post', '/government/competition_course/reject' + head, body)
	},
	courseDetail: function(head) {
		return axios('get', '/government/competition_course/detail' + head)
	},
	//导出数据
	exportGovernmentExcel:function(params){
		return axios('export', '/government/competition_course/download-list',params)
	},
	//统计基础数据
	exportGovernmentStatisticsInfo:function(head,params){
		return axios('get', '/government/competition_course/statistics'+head,params)
	},
	//统计列表
	exportGovernmentStatisticsList:function(head,params){
		return axios('get', '/government/competition_course/statistics-list'+head,params)
	},
	//获取平分占比
	getConfigInfo:function(head,params) {
		return  axios('get', '/competition_course_region_config/detail'+head,params)
	},
	
	/*
		筛查专家接口
	*/
	// 列表
	getFilterExpertList: function(head, body) {
		return axios('get', '/competition_course_filter_expert/competition_course/list' + head, body)
	},
	//获取列表课程详情
	getFilterExpertDetail: function(head) {
		return axios('get', '/competition_course_filter_expert/competition_course/detail' + head)
	},
	// 筛查通过 升到下一级筛查
	filterUpGrade: function(head) {
		return axios('post', '/competition_course_filter_expert/competition_course/upgrade' + head)
	},
	// 撤销  降一级
	filterRollBack: function(head) {
		return axios('post', '/competition_course_filter_expert/competition_course/rollback' + head)
	},
}
Vue.prototype.http = http;
export default http
