// 时间戳+随机数
function getDate() {
	let date = Date.parse(new Date())
	let random = Math.floor(Math.random() * 90 + 10)
	let str = date + random
	return str
}
const getFileName = {
	getFileName() {
		let str = '/course_competition/uploads/'  + getDate() + '_'
		return str
	},
}
Vue.prototype.getFileName = getFileName;
export default getFileName
