<template>
	<div id="app">
		<router-view />
	</div>
	<!-- <div>
		<div class="content-box">
		</div>
		<div class="content-box-1">
			<div class="content-box-title">四川省劳动与实践教育管理平台-课程大赛报名通道</div>
			<div class='content-view'>
				<keep-alive>
					　　<router-view v-if="$route.meta.keepAlive"></router-view>
				</keep-alive>
				<router-view v-if="!$route.meta.keepAlive"></router-view>
			</div> -->
			<!-- <div class="content-box-footer">
				<div>
					主办单位：新华文轩
				</div>
				<div>
					技术支持：存时科技
				</div>
			</div> -->
		<!-- </div>
	</div> -->

</template>
<script>
	export default {
		data() {
			return {

			}
		}
	}
</script>
<style>
	* {
		margin: 0;
		padding: 0;
	}

	tr td {
		border: node;
	}

	a {
		text-decoration: none;
		color: black;
	}

	li {
		list-style: none;
	}

	/* 谷歌滚动条 */
	::-webkit-scrollbar-thumb:horizontal {
		/*水平滚动条的样式*/
		width: 10px;
		background-color: #CCCCCC;
		-webkit-border-radius: 6px;
	}

	::-webkit-scrollbar-track-piece {
		background-color: #fff;
		/*滚动条的背景颜色*/
		-webkit-border-radius: 3px;
		/*滚动条的圆角宽度*/
	}

	::-webkit-scrollbar {
		width: 13px;
		/*滚动条的宽度*/
		height: 8px;
		/*滚动条的高度*/
		-webkit-transition: all 2s linear;
		transition: all 2s linear;
	}

	::-webkit-scrollbar-thumb:vertical {
		/*垂直滚动条的样式*/
		height: 50px;
		background-color: #cacaca;
		-webkit-border-radius: 14px;
		outline: 2px solid #fff;
		outline-offset: -2px;
		border: 2px solid #fff;
	}

	::-webkit-scrollbar-thumb:hover {
		/*滚动条的hover样式*/
		height: 50px;
		background-color: #9f9f9f;
		-webkit-border-radius: 14px;
	}


	/* .content-box {
		background-image: url(assets/img/content-bg.png);
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		min-width: 1220px;
		height: 100%;
		min-height: 800px;
		position: fixed;
		top: 0;
		left: 0;
		z-index: -999;
	}

	.content-box-title {
		width: 100%;
		height: 47px;
		font-size: 2.5vw;
		font-family: Alibaba PuHuiTi;
		font-weight: bold;
		color: #1FB3F7;
		text-shadow: 3px 4px 0px rgba(0, 99, 145, 0.22);
		text-align: center;
		margin-top: 3.5vw;
		letter-spacing: 5px;
	}

	.content-view {
		margin-top: 6vh;
		min-height: 76vh;
	}

	.content-box-footer {
		width: 10vw;
		min-width: 440px;
		margin: 0 auto;
		overflow: hidden;
	}

	.content-box-footer div {
		float: left;
		height: 35px;
		font-size: 20px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #999999;
		margin-right: 40px;
	} */

	/* 浮动 */
	.float-left {
		float: left;
	}

	.float-right {
		float: right;
	}

	.overflow {
		overflow: hidden;
	}

	/* 统一input边框 */
	.input-border {
		width: 100%;
		height: 45px;
		min-height: 45px;
		background: #FFFFFF;
		box-shadow: 0px 5px 5px 0px rgba(31, 180, 249, 0.26), 0px -5px 5px 0px rgba(31, 180, 249, 0.05);
		border-radius: 25px;
		line-height: 45px;
		margin-bottom: 3vh;
	}

	.input-img {
		margin: 8px 15px;
		/* width: 4.5vh;
		height: 4.5vh;
		min-width: 35px;
		min-height: 35px; */
	}

	/* 按钮样式 */
	/* 普通按钮 */
	.btn-primary {
		/* width: 150px !important; */
		height: 45px !important;
		background: #1FB4F9 !important;
		border-radius: 25px !important;
		font-size: 22px !important;
	}

	/*自定义按钮custom-btn*/
	.custom-btn {
		width: 130px;
		height: 45px;
		background: #FFFFFF;
		/* box-shadow: 0px 3px 5px 0px rgba(255,59,61,0.1500), 0px -5px 5px 0px rgba(255,59,61,0.0500); */
		border-radius: 25px;
		text-align: center;
		line-height: 45px;
		cursor: pointer;
		font-weight: bold;
		color: #1FB4F9;
		margin-left: 20px;
	}

	/*返回按钮edit-btn*/
	.edit-btn {
		width: 130px;
		height: 40px;
		background: #E8E8E8;
		/* box-shadow: 0px 3px 5px 0px rgba(255,59,61,0.1500), 0px -5px 5px 0px rgba(255,59,61,0.0500); */
		border-radius: 5px;
		cursor: pointer;
		font-weight: bold;
		color: #333333;
		margin-right: 20px;
		border: none;
		outline: none;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.edit-btn:hover {
		background: #D1D1D1;
	}
	.edit-btn img {
		margin-right: 15px;
	}

	/* 按钮颜色 */
	.btn-corlor-first {
		background-color: #FB824E;
		color: white !important;
	}
	.btn-corlor-first:hover {
		background: #EE5514;
	}
	
	.btn-corlor-second {
		background-color: #1FB4F9 !important;
		color: white !important;
	}

	.el-dialog {
		display: flex;
		flex-direction: column;
		margin: 0 !important;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-height: calc(100% - 30px);
		max-width: calc(100% - 30px);
		min-width: 300px;
	}


	.el-radio__input.is-checked .el-radio__inner {
		border-color: #1FB4F9;
		background: #1FB4F9;
	}

	.el-radio__input.is-checked+.el-radio__label {
		color: #1FB4F9;
	}

	.el-radio__input.is-checked .el-radio__inner::after {
		content: '';
		width: 10px;
		height: 5px;
		border: 1px solid white;
		border-top: transparent;
		border-right: transparent;
		text-align: center;
		display: block;
		position: absolute;
		top: 1px;
		left: 1px;
		vertical-align: middle;
		transform: rotate(-45deg);
		border-radius: 0px;
		background: none;
	}





	/*图片上传-CSS*/
	.el-upload--picture-card {
		border: 1px dashed #d9d9d9 !important;
		background: #F5F5F5 !important;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		width: 100px !important;
		height: 100px !important;
		line-height: 30px !important;
	}

	.add-img {
		padding-top: 15px !important;
		box-sizing: border-box;
	}

	.add-img>div {
		color: #666666;
	}

	.el-upload--picture-card:hover {
		border: 1px dashed #999999 !important;
	}

	.el-upload--picture-card:hover .add-img {
		color: #333333 !important;
	}

	.add-img img {
		width: 30px;
		height: 30px;
	}

	.el-upload--picture-card:hover {
		color: black !important;
	}

	/*图片list*/
	.el-upload-list__item,
	.is-ready img {
		width: 100px !important;
		height: 100px !important;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	/*隐藏*/
	/*拖拽上传-CSS*/
	.el-upload-dragger {
		border-radius: 0 !important;
		width: 610px !important;
		height: 120px !important;
		/* line-height: 120px; */
		font-size: 16px;
		padding-top: 20px;
	}

	.el-upload-dragger .el-upload__text em {
		color: #1FB4F9 !important;
	}

	.el-upload-dragger .el-upload__text p {
		font-size: 10px;
		color: #999999;
	}

	/*拖拽上传list-CSS*/
	.el-upload-list,
	.el-upload-list--text {
		width: 300px;
	}

	.el-upload-list__item-name {
		width: 300px;
		border: 1px dashed #E5E5E5;
		/*margin-bottom: 25px;*/
	}

	.upload-demo /deep/ .el-upload-list {
		display: none;
	}

	.my-upload-list {
		font-size: 14px;
		color: #797979;
	}

	.my-upload-list>div {
		width: 610px;
		height: 43px;
		/* border: 1px dashed #d9d9d9; */
		border-bottom: 1px solid #E5E5E5;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
	}
	.my-upload-list>div:hover {
		background: rgba(245,245,245,0.3900);;
	}
	.my-upload-list span {
		margin-right: 10px;
		display: inline-block;
		padding: 6px 12px;
		line-height: 14px;
		border-radius: 5px;
	}
	.my-upload-list span:hover {
		background: rgba(230,230,230,0.3900);
	}
	.upload-flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.my-upload-list img {
		margin: 0 10px;
	}

	.show-flie-open {
		cursor: pointer;
		color: #1FB3F7;
	}

	.my-close {
		cursor: pointer;
		color: red;
	}

	.upload-demo .el-upload-list {
		display: none !important;
	}

	.upload-demo .el-upload-list--text {
		display: none !important;
	}

	/* 单选样式 1FB4F9 */
	.el-checkbox.is-bordered.is-checked {
		border-color: #1FB4F9
	}

	.el-checkbox__input.is-checked+.el-checkbox__label {
		color: #1FB4F9
	}

	.el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		background-color: #1FB4F9;
		border-color: #1FB4F9
	}
	/deep/ .el-select-dropdown .el-select-dropdown__wrap .el-select-dropdown__item {
		color: #FF0000 !important;
	}
	.export-drop-down-select-class .el-scrollbar{
		padding-top: 50px !important;
		position: relative;
	}
	
	/*dialog水平垂直居中-CSS*/
	
	.el-dialog {
	    display: flex;
	    flex-direction: column;
	    margin: 0 !important;
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%, -50%);
	    max-height: calc(100% - 30px);
	    max-width: calc(100% - 30px);
	    min-width: 300px;
	    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	}
	.el-dialog__body {
		padding: 20px !important;
	}
	.el-dialog__header {
	    height: 60px !important;
	    padding: 0;
	    line-height: 60px;
	    margin: 0 20px;
	    border-bottom: 1px solid #E5E5E5;
	}
	
	.el-dialog__title {
	    color: #333333 !important;
	    font-weight: bold !important;
	    font-size: 16px !important;
	}
	
	
	/*dialog关闭icon-CSS*/
	
	.el-dialog__headerbtn .el-dialog__close {
	    color: #666666;
	}
	.dialog-footer button {
		padding: 7px 16px;
		border: none;
		cursor: pointer;
		border-radius: 5px;
	}
	.comment-cancel-btn {
		background: #E8E8E8;
		color: #333333 ;
	}
	.comment-cancel-btn:hover {
		background: #D1D1D1;
	}
	.comment-confirm-btn {
		background: #FF3B3D;
		color: #ffffff;
	}
	.comment-confirm-btn:hover {
		background: #C6191F;
	}
	.dialog-footer button:nth-of-type(1) {
	    margin-right: 40px;
	}
	.w-100 {
		width: 100px;
	}
	.w-200 {
		width: 200px;
	}
	.w-300 {
		width: 300px;
	}
	.comment-margin-right-10 {
		margin-right: 10px;
	}
	.comment-margin-right-5 {
		margin-right: 5px;
	}
	.comment-margin-bottom-10 {
		margin-bottom: 10px;
	}
	.comment-margin-bottom-5 {
		margin-bottom: 5px;
	}
	.comment-margin-right-min {
		margin-right: 20px;
	}
</style>
