import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'
Vue.use(VueRouter)

const routes = [
	// {
	// 	path: '/',
	// 	name: 'region',
	// 	meta: {
	// 		title: '选择区域'
	// 	},
	// 	component: () => import('../views/region.vue')
	// },
	{
		path: '/',
		name: 'home',
		meta: {
			title: '首页'
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/home.vue'),
		children: [
			{
				path: '/',
				name: 'login',
				meta: {
					title: '首页',
				},
				component: login
			},
			{
				path: '/login',
				name: 'login',
				meta: {
					title: '首页'
				},
				component: () => import( /* webpackChunkName: "about" */ '../views/login.vue')
			},
			{
				path: '/index',
				name: 'index',
				meta: {
					title: '首页'
				},
				component: () => import(  '../pages/index.vue')
			},
			{
				path: '/course',
				name: 'course',
				meta: {
					title: JSON.parse(sessionStorage.getItem('region_theme_text')) ? JSON.parse(sessionStorage.getItem('region_theme_text')) : '课程',
				},
				component: () => import(  '../pages/course.vue')
			},
			{
				path: '/review',
				name: 'review',
				meta: {
					title: JSON.parse(sessionStorage.getItem('region_theme_text')) ? JSON.parse(sessionStorage.getItem('region_theme_text')) : '课程',
				},
				component: () => import(  '../pages/review.vue')
			},
			{
				path: '/review_detail',
				name: 'review_detail',
				meta: {
					title: '详情',
				},
				component: () => import(  '../pages/review_detail.vue')
			},
			{
				path: '/store',
				name: 'store',
				meta: {
					title: '添加' + (JSON.parse(sessionStorage.getItem('region_theme_text')) ? JSON.parse(sessionStorage.getItem('region_theme_text')) : '课程'),
				},
				component: () => import(  '../pages/store.vue')
			},
			{
				path: '/govermentList',
				name: 'govermentList',
				meta: {
					title: (JSON.parse(sessionStorage.getItem('region_theme_text')) ? JSON.parse(sessionStorage.getItem('region_theme_text')) : '课程') + '列表'
				},
				component: () => import('../pages/govermentList.vue')
			},
			{
				path: '/Statistics',
				name: 'Statistics',
				meta: {
					title: '数据统计'
				},
				component: () => import('../pages/Statistics.vue')
			},
			{
				path: '/filter',
				name: 'filter',
				meta: {
					title: '筛查' + (JSON.parse(sessionStorage.getItem('region_theme_text')) ? JSON.parse(sessionStorage.getItem('region_theme_text')) : '课程'),
				},
				component: () => import(  '../pages/filter.vue')
			},
		]
	},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
	document.title = to.meta.title
	next()
})
export default router
