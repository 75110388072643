// import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from './util/http.js'
// file-path
import file_path from './file_path/index'
import util from './util/util.js'
Vue.prototype.alOssUrl = process.env.VUE_APP_ALOSSURL
const ElementUI = require('element-ui')
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
