import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  isLogin:sessionStorage.getItem('isLogin') ? sessionStorage.getItem('isLogin') : false
  },
  getters:{
	  getState(state){
		  return state.isLogin
	  }
  },
  actions: {
	  //登录成功
	  loginState({ commit }, data){
		  commit('setState', data)
	  }
  },
  mutations: {
  	  setState(state,data){
  		  state.isLogin = data
  		  sessionStorage.setItem('isLogin',data)
  	  }
  },
  modules: {
  }
})
