const util={
	//审批状态配置
	confirm_status: {
		0: {
			'key': '未提交审核',
			'code': 0,
			'remarks': '（无需政府审批）'
		},
		1: {
			'key': '未提交审核',
			'code': 1,
			'remarks': '（需要政府审批）'
		},
		2: {
			'key': '待研学机构添加备案',
			'code': 2,
			'remarks': '（需要政府审批）'
		},
		3: {
			'key': '待研学机构添加备案',
			'code': 3,
			'remarks': '（无需政府审批）'
		},
		4: {
			'key': '待基地/机构确认',
			'code': 4,
			'remarks': '（需要政府审批）'
		},
		5: {
			'key': '待基地/机构确认',
			'code': 5,
			'remarks': '（无需政府审批）'
		},
		6: {
			'key': '基地/机构确认失败',
			'code': 6,
			'remarks': '（需要政府审批）'
		},
		7: {
			'key': '基地/机构确认失败',
			'code': 7,
			'remarks': '（无需政府审批）'
		},
		8: {
			'key': '待主管部门审核',
			'code': 8,
		},
		9: {
			'key': '审核失败',
			'code': 9,
		},
		10: {
			'key': '审核成功',
			'code': 10,
			'remarks': '（需要政府审批）'
		},
		11: {
			'key': '审核成功',
			'code': 11,
			'remarks': '（无需政府审批）'
		},
		12: {
			'key': '不需要审核',
			'code': 12,
		},
		13: {
			'key': '已撤销',
			'code': 13,
		}
	},
	//遍历对象，值为空就删除这个键
	delObjItem(obj) {
		let newObj = obj
		for (var i in newObj) {
			if (newObj[i] == '') {
				delete newObj[i]
			}
		}
		return newObj
	},
	config:{
		competition_status:'competition_status',
		solicitation_requirements:'solicitation_requirements',
		operation_notice:'operation_notice',
		competition_notification:'competition_notification',
		score_distributed: 'score_distributed'
	},
	//判断密码是否符合规范
	reg:{
		regPwd:/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9]{8,32}$/,
		regPwdMsg:'您的密码复杂度太低（密码中必须包含大小写字母+数字，长度8-32位）!',
		resetPwdText:'Abc123456'
	},
	//设置文件上传icon
	setFileIcon(res) {
		const type = res.split('.').pop()
		let src = ''
		switch (type) {
			case 'doc':
			case 'docx':
				src = require('@/assets/file-icon/word.png');
				break;
			case 'xlsx':
			case 'xls':
				src = require('@/assets/file-icon/excel.png');
				break;
			case 'pdf':
				src = require('@/assets/file-icon/pdf.png');
				break
			case 'ppt':
			case 'pptx':
				src = require('@/assets/file-icon/ppt.png');
				break
			case 'jpg':
				src = require('@/assets/file-icon/jpg.png');
				break
			case 'png':
				src = require('@/assets/file-icon/png.png');
				break
			case 'mp4':
				src = require('@/assets/file-icon/mp4.png');
				break
		}
		return src
	},
	//读取oss图片大小
	imgSize: {
		imgG1: '!g1',
		imgG2: '!g2',
		imgQ30: '!q30',
		imgQ70: '!q70',
		imgQ15: '!q15',
		imgQ40: '!q40',
		imgQ100: '!q100',
		imgQ90: '!q90',
	},
	
	// setStage:{
	// 	1:'小学组',
	// 	2:'初中组',
	// 	3:'高中组',
	// },
	setStage(arr) {
		if(arr.length == 0) {
			return ''
		}else {
			let str = ''
			for(let i = 0; i < arr.length; i++) {
				str = str + arr[i] + ' '
			}
			return str
		}
	},
	
	// 获取文件大小
	getFileSize(fileSize) {
		if (fileSize < 1024) {
			return fileSize + 'B';
		} else if (fileSize < (1024 * 1024)) {
			var temp = fileSize / 1024;
			temp = temp.toFixed(2);
			return temp + 'KB';
		} else if (fileSize < (1024 * 1024 * 1024)) {
			var temp = fileSize / (1024 * 1024);
			temp = temp.toFixed(2);
			return temp + 'MB';
		} else {
			var temp = fileSize / (1024 * 1024 * 1024);
			temp = temp.toFixed(2);
			return temp + 'GB';
		}
	},
	
	// 获取对应key的值
	getKeyFrontValue(res) {
		let arr = JSON.parse(sessionStorage.getItem('region_key'))
		let itemKey = 'course.' + res
		let str = ''
		arr.forEach((item => {
			if(item.key == itemKey) {
				str = item.value
			}
		}))
		return str
	}
}
Vue.prototype.util = util;