<template>
	<div>
		<div id="bg_box" class="content" v-if="is_login_register">
			<div class='form' style="margin-top: 5vw;margin-left: 3vw;">
				<div class='form-content'>
					<div class="form-title overflow">
						<div class="float-left comment-margin-right-middle">
							<div :class="isActive ? 'active':'no-active'" @click="my_tabs('login')">用户登录</div>
						</div>
						<div class="float-left">
							<div :class="isActive ? 'no-active':'active'" @click="my_tabs('register')">立即注册</div>
						</div>
					</div>
					<div class='login-form' v-if="isActive">
						<el-form :model="loginForm" ref="loginForm">
							<div class="input-border" style="margin-bottom: 60px;">
								<el-row>
									<el-col :span='1'>
										<img src="../assets/img/phone.png" alt="" class="input-img">
									</el-col>
									<el-col :span='19' :offset="2">
										<el-form-item prop="username"
											:rules="[{ required: true, message: '请输入账号', trigger: 'change' }, ]">
											<el-input placeholder="请输入账号" v-model="loginForm.username" clearable
												></el-input>
										</el-form-item>
									</el-col>
								</el-row>
							</div>
							<div class="input-border" style="margin-bottom: 60px;">
								<el-row>
									<el-col :span='1'>
										<img src="../assets/img/pwd.png" alt="" class="input-img">
									</el-col>
									<el-col :span='19' :offset="2">
										<el-form-item prop="password"
											:rules="[{ required: true, message: '请输入密码', trigger: 'change' }, ]">
											<el-input placeholder="请输入密码" v-model="loginForm.password" show-password>
											</el-input>
										</el-form-item>
									</el-col>
								</el-row>
							</div>
						</el-form>

						<div class="login-form-footer overflow">
							<div class="float-left forget-pwd" @click="forget_pwd">忘记密码？</div>
							<div class="float-right">
								<el-button type="primary" class='btn-primary login-btn' @click="login('loginForm')" :loading='btnLogin'>立即登录
								</el-button>
							</div>
						</div>
					</div>
					<div class='register' v-else>
						<el-form :model="registerForm" ref="registerForm">
							<div class="input-border">
								<el-row>
									<el-col :span='1'>
										<img src="../assets/img/phone.png" alt="" class="input-img">
									</el-col>
									<el-col :span='19' :offset="2">
										<el-form-item prop="username"
											:rules="[{ required: true, message: '请输入账号', trigger: 'blur' }, ]">
											<el-input placeholder="请输入账号" v-model="registerForm.username" clearable
												maxlength="11" show-word-limit>
											</el-input>
										</el-form-item>
									</el-col>
								</el-row>
							</div>
							<div>
								<el-row>
									<el-col :span='16'>
										<el-row class="input-border">
											<el-col :span='2'>
												<img src="../assets/img/baohu.png" alt="" class="input-img">
											</el-col>
											<el-col :span='17' :offset="3">
												<el-form-item prop="code"
													:rules="[{ required: true, message: '请输入验证码', trigger: 'blur' }, ]">
													<el-input placeholder="请输入验证码" v-model="registerForm.code"
														clearable>
													</el-input>
												</el-form-item>
											</el-col>
										</el-row>
									</el-col>
									<el-col :span='5' :offset='1'>
										<el-button :disabled='registerGetCodeDisabled' class="getCode"
											@click="registerGetCode">
											{{registerGetCodeText}}
										</el-button>
									</el-col>
								</el-row>
							</div>
							<div class="input-border">
								<el-row>
									<el-col :span='1'>
										<img src="../assets/img/pwd.png" alt="" class="input-img">
									</el-col>
									<el-col :span='19' :offset="2">
										<el-form-item prop="password"
											:rules="[{ required: true, message: '请输入密码', trigger: 'blur' }, ]">
											<el-input placeholder="请输入密码" v-model="registerForm.password" show-password>
											</el-input>
										</el-form-item>
									</el-col>
								</el-row>
							</div>
							<el-form-item prop="gender"
								:rules="[{ required: true, message: '请选择性别', trigger: 'blur' }, ]">
								<div :class="registerForm.gender == '1' ? 'register-gender-active float-left':'register-gender-null float-left'"
									@click="genderTabs('1')">男</div>
								<div :class="registerForm.gender == '2' ? 'register-gender-active float-right':'register-gender-null float-right'"
									@click="genderTabs('2')">女</div>
							</el-form-item>
						</el-form>
						<div class="login-form-footer overflow">
							<div class="float-left forget-pwd" @click="forget_pwd">忘记密码？</div>
							<div class="float-right">
								<el-button type="primary" class='btn-primary login-btn' @click="register('registerForm')" :loading='btnLogin'>立即注册
								</el-button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="bg-img-box">
				<img :src="boxBgUrl" style="width: 100%;height: 100%;" alt="">
			</div>
		</div>
		<!-- 忘记密码 -->
		<div v-else class="forget-pwd-content">
			<div @click="edit" class="forget-pwd-content-edit">
				<span><img src="../assets/img/no-edit.png"></span>
				<span>返回</span>
			</div>
			<div class="forget-pwd-container">
				<div class="forget-pwd-title">忘记密码</div>
				<div class="forget-pwd-form">
					<el-form :model="forgetPwdForm" ref="forgetPwdForm">
						<div class="input-border forget-pwd-content-buttom">
							<el-row>
								<el-col :span='1'>
									<img src="../assets/img/phone.png" alt="" class="input-img">
								</el-col>
								<el-col :span='19' :offset="2">
									<el-form-item prop="phone"
										:rules="[{ required: true, message: '请输入手机号', trigger: 'blur' }, ]">
										<el-input placeholder="请输入手机号" v-model="forgetPwdForm.phone" clearable
											maxlength="11" show-word-limit></el-input>
									</el-form-item>
								</el-col>
							</el-row>
						</div>
						<div>
							<el-row>
								<el-col :span='17'>
									<el-row class="input-border forget-pwd-content-buttom">
										<el-col :span='1'>
											<img src="../assets/img/baohu.png" alt="" class="input-img">
										</el-col>
										<el-col :span='19' :offset="3">
											<el-form-item prop="code"
												:rules="[{ required: true, message: '请输入验证码', trigger: 'blur' }, ]">
												<el-input placeholder="请输入验证码" v-model="forgetPwdForm.code" autocomplete="off" clearable>
												</el-input>
											</el-form-item>
										</el-col>
									</el-row>
								</el-col>
								<el-col :span='5' :offset='1'>
									<el-button :disabled='resetGetCodeDisabled' class="getCode" @click='resetGetCode'>
										{{resetGetCodeText}}
									</el-button>
								</el-col>
							</el-row>
						</div>
						<div class="input-border forget-pwd-content-buttom">
							<el-row>
								<el-col :span='1'>
									<img src="../assets/img/pwd.png" alt="" class="input-img">
								</el-col>
								<el-col :span='19' :offset="2">
									<el-form-item prop="password"
										:rules="[{ required: true, message: '请输入密码', trigger: 'blur' }, ]">
										<el-input placeholder="请输入密码" v-model="forgetPwdForm.password" autocomplete="off" clearable  show-password>
										</el-input>
									</el-form-item>
								</el-col>
							</el-row>
						</div>
						<div class="input-border forget-pwd-content-buttom">
							<el-row>
								<el-col :span='1'>
									<img src="../assets/img/pwd.png" alt="" class="input-img">
								</el-col>
								<el-col :span='19' :offset="2">
									<el-form-item prop="checkPass"
										:rules="[{ required: true, message: '请输入密码', trigger: 'blur' }, ]">
										<el-input placeholder="请再此输入密码" v-model="forgetPwdForm.checkPass" clearable  show-password>
										</el-input>
									</el-form-item>
								</el-col>
							</el-row>
						</div>
					</el-form>

					<div class="forget-pwd-form-btn">
						<el-button type="primary" class='btn-primary login-btn' @click="resetPwd('forgetPwdForm')" :loading='btnLogin'>立即重置
						</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'login',
		data() {
			return {
				//是否是登录或者注册
				is_login_register: true,
				//选中状态
				isActive: true,
				//登录
				loginForm: {
					username: '',
					password: '',
				},
				// 注册
				registerForm: {
					username: '',
					password: '',
					code: '',
					gender:'1',
				},
				registerGetCodeText: '获取验证码',
				registerGetCodeDisabled: false,
				// 忘记密码
				forgetPwdForm: {
					phone: '',
					password: '',
					checkPass: '',
					code: '',
				},
				resetGetCodeText: '获取验证码',
				resetGetCodeDisabled: false,
				
				btnLogin:false,
				boxBgUrl: ''
			}
		},
		mounted() {
			// 监听回车事件
			let _this = this
			document.onkeydown = function(e) {
				if (e.keyCode === 13) {
					if(_this.is_login_register){
						if(_this.isActive){
							//登录
							_this.login('loginForm')
						}else{
							//注册
							_this.register('registerForm')
						}
					}else{
						//忘记密码
						_this.resetPwd('forgetPwdForm')
					}
				}
			}
		},
		methods: {

			//切换方法
			my_tabs(type) {
				if (type == 'login') {
					this.isActive = true
				} else {
					this.isActive = false
				}
			},
			// 忘记密码
			forget_pwd() {
				this.is_login_register = false
			},
			// 忘记密码-返回
			edit() {
				this.is_login_register = true
			},
			// 重置密码
			resetPwd(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.forgetPwdForm.checkPass != this.forgetPwdForm.password) {
							this.$message.error('两次密码不一致！');
							return
						}
						var pwdRegex =new RegExp(this.util.reg.regPwd)
						if(!pwdRegex.test(this.forgetPwdForm.checkPass)){
							this.$message({
								message: this.util.reg.regPwdMsg,
								type: 'error'
							});
							return
						}
						let obj = {
							phone: this.forgetPwdForm.phone,
							code: this.forgetPwdForm.code,
							password: this.forgetPwdForm.checkPass
						}
						this.btnLogin=true
						this.http.forget('', obj).then((res) => {
							this.btnLogin=false
							if (res.data.code == 200) {
								this.$message({
									message: '密码重置成功！',
									type: 'success'
								})
								this.is_login_register = true
								this.isActive = true
							} else {
								this.$message.error(res.data.msg);
								return
							}
						})
					} else {
						return false;
					}
				});
			},
			// 重置密码获取验证码
			resetGetCode() {
				if (this.forgetPwdForm.phone == '') {
					this.$message.error('请输入手机号码');
					return
				}
				if (!/^1[3456789]\d{9}$/.test(this.forgetPwdForm.phone)) {
					this.$message({
						message: '请填写正确的手机号!',
						type: 'error'
					})
					return
				}
				this.http.is_register('?phone='+this.forgetPwdForm.phone).then((res)=>{
					if (res.data.code == 200) {
						if(!res.data.data.is_registered){
							this.$message({
								message: '该账号未注册！',
								type: 'success'
							});
						}
					} else {
						this.$message.error(res.data.msg);
						return
					}
				})
				this.http.getCode('', {
					phone: this.forgetPwdForm.phone
				}).then((res) => {
					if (res.data.code == 200) {
						this.$message({
							message: '验证码发送成功！',
							type: 'success'
						});
						sessionStorage.setItem('signature', JSON.stringify(res.data.data
							.signature))
					} else {
						this.$message.error(res.data.msg);
						return
					}
				})
				// 60S倒计时
				this.resetGetCodeText = 60
				let _this = this
				this.resetGetCodeDisabled = true
				var time = setInterval(function() {
					_this.resetGetCodeText = _this.resetGetCodeText - 1
					// 判断是否为0+是否是忘记密码
					if (_this.resetGetCodeText == 0 || _this.is_login_register) {
						_this.resetGetCodeText = '获取验证码'
						_this.resetGetCodeDisabled = false
						clearInterval(time)
					}
				}, 1000)
			},
			// 登录
			login(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let obj = {
							username: this.loginForm.username,
							password: this.loginForm.password,
							// region_code: JSON.parse(sessionStorage.getItem('region_code'))
						}
						this.btnLogin=true
						this.http.login('?region_code=' + JSON.parse(sessionStorage.getItem('region_code')), obj).then((res) => {
							this.btnLogin=false
							if (res.data.code == 200) {
								this.$message({
									message: '登录成功！',
									type: 'success'
								});
								this.$store.dispatch("loginState", true);
								sessionStorage.setItem('userInfo',JSON.stringify(res.data.data.info))
								sessionStorage.setItem('token', JSON.stringify(res
									.data.data
									.token))
								if(res.data.data.info.role == 'government.other') {
									let key = '?key=' + this.util.config.score_distributed
									this.http.config_detail(key, '').then((res1) => {
										sessionStorage.setItem('config',JSON.stringify(res1.data.data.competition_config))
										this.$router.push({
											name: 'govermentList'
										})
									})
								} else {
									this.$router.push({
										name: 'index'
									})
								}
								//禁止在登录后通过浏览器返回
								history.pushState(null, null, document.URL);
								window.addEventListener(
									"popstate",
									function(e) {
										history.pushState(null, null, document.URL);
									},
									false
								);
							} else {
								this.loginForm.password=''
								this.$message.error(res.data.msg);
							}
						})
					} else {
						return false;
					}
				});
			},
			
			//注册切换性别
			genderTabs(type){
				if(type==1){
					this.registerForm.gender=1
				}else{
					this.registerForm.gender=2
				}
			},
			//注册
			register(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						var pwdRegex =new RegExp(this.util.reg.regPwd)
						if(!pwdRegex.test(this.registerForm.password)){
							this.$message({
								message: this.util.reg.regPwdMsg,
								type: 'error'
							});
							return
						}
						let obj = {
							phone: this.registerForm.username,
							code: this.registerForm.code,
							password: this.registerForm.password,
							gender:this.registerForm.gender
						}
						this.btnLogin=true
						this.http.register('', obj).then((res) => {
							this.btnLogin=false
							if (res.data.code == 200) {
								this.$message({
									message: '注册成功！',
									type: 'success'
								});
								this.isActive = true
							} else {
								this.$message.error(res.data.msg);
							}
						})
					} else {
						return false;
					}
				});
			},
			//注册获取验证码
			registerGetCode() {
				if (this.registerForm.username == '') {
					this.$message.error('请输入手机号码');
					return
				}
				if (!/^1[3456789]\d{9}$/.test(this.registerForm.username)) {
					this.$message({
						message: '请填写正确的手机号!',
						type: 'error'
					})
					return
				}
				this.http.getCode('', {
					phone: this.registerForm.username
				}).then((res) => {
					if (res.data.code == 200) {
						this.$message({
							message: '验证码发送成功！',
							type: 'success'
						});
						sessionStorage.setItem('signature', JSON.stringify(res.data.data.signature))
					} else {
						this.$message.error(res.data.msg);
						return
					}
				})
				// 60S倒计时
				this.registerGetCodeText = 60
				let _this = this
				this.registerGetCodeDisabled = true
				var time = setInterval(function() {
					_this.registerGetCodeText = _this.registerGetCodeText - 1
					// 判断是否为0+是否是登录注册+是否是注册
					if (_this.registerGetCodeText == 0 || !_this.is_login_register || _this.isActive) {
						_this.registerGetCodeText = '获取验证码'
						_this.registerGetCodeDisabled = false
						clearInterval(time)
					}
				}, 1000)
			},
		},
		mounted() {
			let _this = this;
			let timer = setInterval(() => {
				if (sessionStorage.getItem('region_detail')) {
					_this.boxBgUrl = _this.alOssUrl + String(JSON.parse(sessionStorage.getItem('region_detail')).region_bg)
					clearInterval(timer)
				}
			}, 50)
		},
		watch: {
			isActive(res) {
				this.loginForm.username = ''
				this.loginForm.password = ''
				this.registerForm.username = ''
				this.registerForm.password = ''
				this.registerForm.code = ''
				if (res) {
					this.$nextTick(() => {
						this.$refs['loginForm'].resetFields();
					})
				} else {
					this.$nextTick(() => {
						this.$refs['registerForm'].resetFields();
					})
				}
			},
			is_login_register() {
				this.forgetPwdForm.phone = ''
				this.forgetPwdForm.password = ''
				this.forgetPwdForm.checkPass = ''
				this.forgetPwdForm.code = ''
			}
		}
	}
</script>
<style scoped="scoped">
	.content {
		width: 68%;
		/* height: 73vh; */
		height: 600px;
		min-height: 600px;
		min-width: 1100px;
		margin: 0 auto;
		background-color: #FFFFFF;
		/* background-image: url(../assets/img/login-bg-right.png); */
		background-size: contain;
		/* background-position: 600px 0; */
		background-position: right;
		background-repeat: no-repeat;
		/* padding: 5vw 8vw; */
		box-sizing: border-box;
		border-radius: 12px;
	}
	#bg_box {
		display: flex;
		justify-content: space-between;
	}
	.bg-img-box {
		max-width: 600px;
		height: 100%;
		border-radius: 0 12px 12px 0;
		overflow: hidden;
	}
	.form-content {
		width: 22vw;
		min-width: 400px;
	}

	/*login-title*/
	.form-title>div {
		font-size: 28px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		cursor: pointer;
		margin-bottom: 60px;
	}

	/* 未选中 */
	.no-active {
		color: #CCCCCC;
		height: 45px;
		border-bottom: 4px solid #CCCCCC;
	}

	/* 选中 */
	.active {
		color: #FF3B3D;
		height: 45px;
		border-bottom: 4px solid #FF3B3D;
	}

	.input-border /deep/ .el-input__inner {
		border: none;
		height: 45px;
		min-height: 45px;
		line-height: 60px;
	}

	.input-border .input-img {
		width: 30px !important;
		height: 30px !important;
	}

	/* footer */
	.login-form-footer {
		margin-top: 50px;
	}
	.btn-primary {
		background: #FF3B3D !important;
		/* border-radius: 5px; */
		border: none;
	}
	.btn-primary:hover {
		background: #C6191F !important;
	}
	.login-btn {
		background: #FF3B3D;
		border-radius: 5px !important;
	}
	/* 忘记密码 */
	.forget-pwd {
		height: 50px;
		display: table-cell;
		vertical-align: middle;
		padding-top: 10px;
		box-sizing: border-box;
		font-size: 20px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #CCCCCC;
		cursor: pointer;
	}


	/* 忘记密码 */
	.forget-pwd-content {
		width: 600px;
		height: 600px;
		margin: 0 auto;
		padding: 1vw 1.5vw;
		box-sizing: border-box;
		background: #FFFEFF;
		background-image: none !important;
		border-radius: 12px;
		margin-top: 10vh;
	}
	.forget-pwd-content-buttom{
		margin-bottom: 40px;
	}
	.forget-pwd-container {
		width: 80%;
		margin: 0 auto;
		margin-top: 40px;
	}

	.forget-pwd-title {
		width: 115px;
		height: 45px;
		font-size: 28px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #FF3B3D;
		border-bottom: 4px solid #FF3B3D;
	}

	.forget-pwd-form {
		margin-top: 40px;
	}

	.forget-pwd-form-btn {
		text-align: center;
	}

	.forget-pwd-content-edit {
		color: #333333;
		cursor: pointer;
		font-size: 20px;
	}

	.forget-pwd-content-edit span {
		margin-right: 10px;
	}

	/* 获取验证码 */
	.getCode {
		width: 130px;
		height: 45px;
		background: #FFFFFF;
		box-shadow: 0px 3px 5px 0px rgba(255,59,61,0.1500), 0px -5px 5px 0px rgba(255,59,61,0.0500);
		border-radius: 25px;
		cursor: pointer;
		font-weight: 600;
		color: #FF3B3D;
		margin-right: 20px;
		border: none;
		outline: none;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
	}

	/* 提示语位置 */
	.content /deep/ .el-form-item__error {
		padding-top: 10px;
		padding-left: 15px;
	}

	.forget-pwd-content /deep/ .el-form-item__error {
		padding-top: 10px;
		padding-left: 15px;
	}
	
	/* 注册按钮 */
	.register-gender-active{
		width: 48%;
		height: 45px;
		background: #FFBD59;
		box-shadow: 0px 3px 5px 0px rgba(255,59,61,0.1500), 0px -5px 5px 0px rgba(255,59,61,0.0500);
		border-radius: 25px;
		text-align: center;
		line-height: 45px;
		color: white;
		cursor: pointer;
		font-weight: bold;
	}
	.register-gender-null{
		width: 48%;
		height: 45px;
		background: #FFFFFF;
		box-shadow: 0px 3px 5px 0px rgba(255,59,61,0.1500), 0px -5px 5px 0px rgba(255,59,61,0.0500);
		border-radius: 25px;
		text-align: center;
		line-height: 45px;
		color: #777777;
		cursor: pointer;
		font-weight: bold;
	}
	.comment-margin-right-middle {
		margin-right: 40px;
	}
</style>
